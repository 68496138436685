import { EmptyString } from '@components/utils/constants'
import { AnalyticsEventType } from '..'
import { EVENTS_MAP } from '../constants'

export const KWIKPASS_EVENTS: any = {
  eventTypes: {
    [AnalyticsEventType.PDP_VIEW]: EVENTS_MAP.EVENT_TYPES.PageViewKp,
    [AnalyticsEventType.PLP_VIEW]: EVENTS_MAP.EVENT_TYPES.PageViewKp,
    [AnalyticsEventType.OTHER_PAGE]: EVENTS_MAP.EVENT_TYPES.PageViewKp,
  },

  events: {
    [AnalyticsEventType.PLP_VIEW]: {
      transformMap: {
        type: (source: any) => source?.type,
        data: (source: any) => ({
          collection_id: source?.data?.collection_id,
          name: source?.data?.name || EmptyString,
          handle: source?.data?.slug || EmptyString,
        }),
      },
    },

    [AnalyticsEventType.PDP_VIEW]: {
        transformMap: {
          type: (source: any) => source?.type,
          data: (source: any) => ({
            product_id: source?.data?.recordId || source?.data?.productId,
            variant_id: source?.data?.variantGroupCode || EmptyString,
            image_url: source?.data?.image || EmptyString,
            name: source?.data?.name || EmptyString,
            price: source?.data?.price?.raw?.withTax || EmptyString,
            handle: source?.data?.slug || source?.data?.link || EmptyString,
          }),
        },
    },

    [AnalyticsEventType.OTHER_PAGE]: {
      transformMap: {
        type: (source: any) => source?.type,
      },
    },
  },
}
